import { IRole, IRoleConfig } from '../../_shared/models/models-index';
import { RolePermissions } from '../../_shared/models/security';

export class RoleConfig implements IRoleConfig {

  systemAdministratorRoles: IRole[] = [
    { roleId: 1, name: 'System Administrator', permissions: [RolePermissions.ViewManagement, RolePermissions.DataSetContributor, RolePermissions.ReportViewContributor, RolePermissions.QueryContributor, RolePermissions.ResourceStringContibutor]},
    { roleId: 8, name: "System Analyst", permissions: [RolePermissions.ViewManagement, RolePermissions.ResourceStringContibutor] }
  ];
  corporateRoles: IRole[] = [
    { roleId: 2, name: 'Corporate' }
  ];
  org5Roles: IRole[] = [];
  org4Roles: IRole[] = [];
  org3Roles: IRole[] = [];
  org2Roles: IRole[] = [
    { roleId: 3, name: 'Region Manager' }
  ];
  org1Roles: IRole[] = [
    { roleId: 5, name: 'District Manager' }
  ];
  dealerRoles: IRole[] = [
    { roleId: 6, name: 'Dealer' }
  ];
  dealerGroupRoles: IRole[] = [
    { roleId: 7, name: 'Dealer Group' }
  ];

}
