import { ITranslationConfig } from "../translation.config";

export class WebsiteTranslationConfig implements ITranslationConfig {
  public reportTitles = {
    'en': {
      dealerperformance: 'Dealer Performance',
      websiteoverview: 'Website Overview',
      scorecard: 'Website Scorecard',
      websitescorecard: 'Website Scorecard',
      websitesummary: 'Website Summary',
      referrer: 'Referrer Report',
      referrerreport: 'Referrer Report',
      vehiclesummary: 'Vehicle Summary',
      websitesupplierperformance: 'Website Supplier Performance',
      websitesupplierscorecard: 'Website Supplier Scorecard',
      websiteproviderperformance: 'Website Provider Performance',
    },
    'fr-CA': {
      dealerperformance: 'Performance du concessionnaire',
      websiteoverview: 'Aperçu du site Web',
      scorecard: 'Fiche d’évaluation de site Web',
      websitescorecard: 'Fiche d’évaluation de site Web',
      websitesummary: 'Sommaire de site Web',
      referrer: 'Référent',
      referrerreport: 'Référent',
      vehiclesummary: 'Résumé du véhicule',
      websitesupplierperformance: 'Performance du fournisseur de site Web',
      websitesupplierscorecard: 'Tableau de bord du fournisseur',
      provider: 'Fournisseur',
      websiteproviderperformance: 'Performance du fournisseur de site Web',
    }
  };

  public metrics = {
    'en': {
      none: 'None',
      pageViews: 'Page Views',
      pageViewsPreviousMonth: 'Prev. Month Page Views',
      pageViewsMOM: 'Page Views MOM',
      pageViewsPreviousYear: 'Prev. Year Page Views',
      pageViewsYOY: 'Page Views YOY',
      newVdps: 'New VDPs',
      newVdpsPreviousMonth: 'Prev. Month New VDPs',
      newVdpsMOM: 'New VDPs MOM',
      newVdpsPreviousYear: 'Prev. Year New VDPs',
      newVdpsYOY: 'New VDPs YOY',
      usedVdps: 'Used VDPs',
      usedVdpsPreviousMonth: 'Prev. Month Used VDPs',
      usedVdpsMOM: 'Used VDPs MOM',
      usedVdpsPreviousYear: 'Prev. Year Used VDPs',
      usedVdpsYOY: 'Used VDPs YOY',
      servicePageViews: 'Service Views',
      servicePageViewsPreviousMonth: 'Prev. Month Service Views',
      servicePageViewsMOM: 'Service Views MOM',
      servicePageViewsPreviousYear: 'Prev. Year Service Views',
      servicePageViewsYOY: 'Service Views YOY',
      engagementRate: 'Engagement Rate',
      engagementRatePreviousMonth: 'Prev. Month Engagement Rate',
      engagementRateMOM: 'Engagement Rate MOM',
      engagementRatePreviousYear: 'Prev. Year Engagement Rate',
      engagementRateYOY: 'Engagement Rate YOY',
      averageTimeOnSite: 'Avg. Time On Site',
      averageTimeOnSitePreviousMonth: 'Prev. Month Avg. Time On Site',
      averageTimeOnSiteMOM: 'Avg. Time On Site MOM',
      averageTimeOnSitePreviousYear: 'Prev. Year Avg. Time On Site',
      averageTimeOnSiteYOY: 'Avg. Time On Site YOY',
      avgTimeOnSite: 'Avg. Time On Site',
      avgTimeOnSitePreviousMonth: 'Prev. Month Avg. Time On Site',
      avgTimeOnSiteMOM: 'Avg. Time On Site MOM',
      avgTimeOnSitePreviousYear: 'Prev. Year Avg. Time On Site',
      avgTimeOnSiteYOY: 'Avg. Time On Site YOY',
      bounces: 'Bounces',
      bouncesPreviousMonth: 'Prev. Month Bounces',
      bouncesPreviousMTD: 'Prev. Month Bounces',
      bouncesMOM: 'Bounces MOM',
      bouncesPreviousYear: 'Prev. Year Bounces',
      bouncesYOY: 'Bounces YOY',
      bounceRate: 'Bounce Rate',
      bounceRatePreviousMonth: 'Prev. Month Bounce Rate',
      bounceRateMOM: 'Bounce Rate MOM',
      bounceRatePreviousYear: 'Prev. Year Bounce Rate',
      bounceRateYOY: 'Bounce Rate YOY',
      totalVisits: 'Website Visits',
      totalVisitsPreviousMonth: 'Prev. Month Website Visits',
      totalVisitsMOM: 'Website Visits MOM',
      totalVisitsPreviousYear: 'Prev. Year Website Visits',
      totalVisitsYOY: 'Website Visits YOY',
      uniqueVisits: 'Unique Visits',
      uniqueVisitsPreviousMonth: 'Prev. Month Unique Visits',
      uniqueVisitsPreviousMTD: 'Prev. Month Unique Visits',
      uniqueVisitsMOM: 'Unique Visits MOM',
      uniqueVisitsPreviousYear: 'Prev. Year Unique Visits',
      uniqueVisitsYOY: 'Unique Visits YOY',
      visits: 'Visits',
      visitsPreviousMonth: 'Prev. Month Visits',
      visitsMOM: 'Visits MOM',
      visitsPreviousYear: 'Prev. Year Visits',
      visitsYOY: 'Visits YOY',
      engagements: 'Engagements',
      engagementsPreviousMonth: 'Prev. Month Engagements',
      engagementsMOM: 'Engagements MOM',
      engagementsPreviousYear: 'Prev. Year Engagements',
      engagementsYOY: 'Engagements YOY',
      actions: 'Actions',
      actionsPreviousMonth: 'Prev. Month Actions',
      actionsMOM: 'Actions MOM',
      actionsPreviousYear: 'Prev. Year Actions',
      actionsYOY: 'Actions YOY',
      actionRate: 'Action Rate',
      actionRatePreviousMonth: 'Prev. Month Action Rate',
      actionRateMOM: 'Action Rate MOM',
      actionRatePreviousYear: 'Prev. Year Action Rate',
      actionRateYOY: 'Action Rate YOY',
      formSubmissions: 'Form Submissions',
      formSubmissionsPreviousMonth: 'Prev. Month Form Submissions',
      formSubmissionsMOM: 'Form Submissions MOM',
      formSubmissionsPreviousYear: 'Prev. Year Form Submissions',
      formSubmissionsYOY: 'Form Submissions YOY',
      formConversionRate: 'Form Conversion Rate',
      formConversionRatePreviousMonth: 'Prev. Month Form Conversion Rate',
      formConversionRateMOM: 'Form Conversion Rate MOM',
      formConversionRatePreviousYear: 'Prev. Year Form Conversion Rate',
      formConversionRateYOY: 'Form Conversion Rate YOY',
      phoneCalls: 'Phone Calls',
      phoneCallsPreviousMonth: 'Prev. Month Phone Calls',
      phoneCallsMOM: 'Phone Calls MOM',
      phoneCallsPreviousYear: 'Prev. Year Phone Calls',
      phoneCallsYOY: 'Phone Calls YOY',
      phoneConversionRate: 'Phone Conversion Rate',
      phoneConversionRatePreviousMonth: 'Prev. Month Phone Conversion Rate',
      phoneConversionRateMOM: 'Phone Conversion Rate MOM',
      phoneConversionRatePreviousYear: 'Prev. Year Phone Conversion Rate',
      phoneConversionRateYOY: 'Phone Conversion Rate YOY',
      totalContacts: 'Total Contacts',
      totalContactsPreviousMonth: 'Prev. Month Total Contacts',
      totalContactsMOM: 'Total Contacts MOM',
      totalContactsPreviousYear: 'Prev. Year Total Contacts',
      totalContactsYOY: 'Total Contacts YOY',
      totalContactRate: 'Total Contact Rate',
      totalContactRatePreviousMonth: 'Prev. Month Total Contact Rate',
      totalContactRateMOM: 'Total Contact Rate MOM',
      totalContactRatePreviousYear: 'Prev. Year Total Contact Rate',
      totalContactRateYOY: 'Total Contact Rate YOY',
      clickToCalls: 'Click To Call',
      clickToCallsPreviousMonth: 'Prev. Month Click To Call',
      clickToCallsMOM: 'Click To Call MOM',
      clickToCallsPreviousYear: 'Prev. Year Click To Call',
      clickToCallsYOY: 'Click To Call YOY',
      newInventoryDetailViews: 'New Inventory Detail Views',
      newInventoryDetailViewsPreviousMonth: 'Prev. Month New Inventory Detail Views',
      newInventoryDetailViewsMOM: 'New Inventory Detail Views MOM',
      newInventoryDetailViewsPreviousYear: 'Prev. Year New Inventory Detail Views',
      newInventoryDetailViewsYOY: 'New Inventory Detail Views YOY',
      usedInventoryDetailViews: 'Used Inventory Detail Views',
      usedInventoryDetailViewsPreviousMonth: 'Prev. Month Used Inventory Detail Views',
      usedInventoryDetailViewsMOM: 'Used Inventory Detail Views MOM',
      usedInventoryDetailViewsPreviousYear: 'Prev. Year Used Inventory Detail Views',
      usedInventoryDetailViewsYOY: 'Used Inventory Detail Views YOY',
      cpoInventoryDetailViews: 'CPO Inventory Detail Views',
      cpoInventoryDetailViewsPreviousMonth: 'Prev. Month CPO Inventory Detail Views',
      cpoInventoryDetailViewsMOM: 'CPO Inventory Detail Views MOM',
      cpoInventoryDetailViewsPreviousYear: 'Prev. Year CPO Inventory Detail Views',
      cpoInventoryDetailViewsYOY: 'CPO Inventory Detail Views YOY',
      pageViewsPerVisit: 'Page Views Per Visit',
      pageViewsPerVisitPreviousMonth: 'Prev. Month Page Views Per Visit',
      pageViewsPerVisitMOM: 'Page Views Per Visit MOM',
      pageViewsPerVisitPreviousYear: 'Prev. Year Page Views Per Visit',
      pageViewsPerVisitYOY: 'Page Views Per Visit YOY',
      dealerEnrollments: 'Dealer Enrollments',
      averageActions: 'Avg. Actions',
      averageEngagementRate: 'Avg. Engagement Rate',
      averageVisitors: 'Avg. Visitors',
      totals: 'TOTALS',
      vehiclemake: 'Vehicle Makes'
    },
    'fr-CA': {
      provider: 'Fournisseur',
      supplier: 'Fournisseur',
      none: 'None',
      pageViews: 'Page vues',
      pageViewsPreviousMonth: 'Mois préc. Pages vues',
      pageViewsPreviousMTD: 'Pages vues Mois Préc.',
      pageViewsMOM: 'Pages vues MSM',
      pageViewsPreviousYear: 'Pages vues Année Préc.',
      pageViewsYOY: 'Pages vues ASA',
      newVdps: 'Nouveaux VDP',
      newVdpsPreviousMonth: 'Mois préc. Nouveaux VDP',
      newVdpsPreviousMTD: 'Mois préc. Nouveaux VDP',
      newVdpsMOM: 'Nouveaux VDP MSM',
      newVdpsPreviousYear: 'Nouveaux VDP Année Préc.',
      newVdpsYOY: 'Nouveaux VDP ASA',
      newVdpViews: 'Vues VDP Nouveaux',
      newVdpViewsPreviousMonth: 'Vues VDP Nouveaux Mois Préc.',
      newVdpViewsPreviousMTD: 'Vues VDP Nouveaux Mois Préc.',
      newVdpViewsMOM: 'Vues VDP Nouveaux MSM',
      newVdpViewsPreviousYear: 'Vues VDP Nouveaux Année Préc.',
      newVdpViewsYOY: 'Vues VDP Nouveaux ASA',
      vdpNewViews: 'Vues VDP Nouveaux',
      vdpNewViewsPreviousMonth: 'Vues VDP Nouveaux Mois Préc.',
      vdpNewViewsPreviousMTD: 'Vues VDP Nouveaux Mois Préc.',
      vdpNewViewsMOM: 'Vues VDP Nouveaux MSM',
      vdpNewViewsPreviousYear: 'Vues VDP Nouveaux Année Préc.',
      vdpNewViewsYOY: 'Vues VDP Nouveaux ASA',
      usedVdps: 'VDP d\'occasion',
      usedVdpsPreviousMonth: 'Mois préc. VDP d\'occasion',
      usedVdpsPreviousMTD: 'Mois préc. VDP d\'occasion',
      usedVdpsMOM: 'VDP d\'occasion MSM',
      usedVdpsPreviousYear: 'VDP d\'occasion Année Préc.',
      usedVdpsYOY: 'VDP d\'occasion ASA',
      usedVdpViews: 'Vues VDP d’occasion',
      usedVdpViewsPreviousMonth: 'Vues VDP d’occasion Mois Préc.',
      usedVdpViewsPreviousMTD: 'Vues VDP d’occasion Mois Préc.',
      usedVdpViewsMOM: 'Vues VDP d’occasion MSM',
      usedVdpViewsPreviousYear: 'Vues VDP d’occasion Année Préc.',
      usedVdpViewsYOY: 'Vues VDP d’occasion ASA',
      vdpUsedViews: 'Vues VDP d’occasion',
      vdpUsedViewsPreviousMonth: 'Vues VDP d’occasion Mois Préc.',
      vdpUsedViewsPreviousMTD: 'Vues VDP d’occasion Mois Préc.',
      vdpUsedViewsMOM: 'Vues VDP d’occasion MSM',
      vdpUsedViewsPreviousYear: 'Vues VDP d’occasion Année Préc.',
      vdpUsedViewsYOY: 'Vues VDP d’occasion ASA',
      cpoVdps: 'CPO VDP',
      cpoVdpsPreviousMonth: 'Mois préc. CPO VDP',
      cpoVdpsMOM: 'CPO VDP MSM',
      cpoVdpsPreviousYear: 'CPO VDP Année Préc.',
      cpoVdpsYOY: 'CPO VDP ASA',
      cpoVdpViews: 'Vues CPO VDP',
      cpoVdpViewsPreviousMonth: 'Mois préc. Vues CPO VDP',
      cpoVdpViewsMom: 'Vues CPO VDP MSM',
      cpoVdpViewsPreviousYear: 'Année préc. Vues CPO VDP',
      cpoVdpViewsYOY: 'Vues CPO VDP ASA',
      vdpCPOViews: 'Vues CPO VDP',
      vdpCPOViewsPreviousMonth: 'Mois préc. Vues CPO VDP',
      vdpCPOViewsMom: 'Vues CPO VDP MSM',
      vdpCPOViewsPreviousYear: 'Année préc. Vues CPO VDP',
      vdpCPOViewsYOY: 'Vues CPO VDP ASA',
      otherVdps: 'Autres vues PDV',
      otherVdpsPreviousMonth: 'Mois préc. Autres vues PDV',
      otherVdpsPreviousMTD: 'Mois préc. Autres vues PDV',
      otherVdpsMOM: 'Autres vues PDV MSM',
      otherVdpsPreviousYear: 'Autres vues PDV Année Préc.',
      otherVdpsYOY: 'Autres vues PDV ASA',
      otherVdpViews: 'Autres vues PDV',
      otherVdpViewsPreviousMonth: 'Mois préc. Autres vues PDV',
      otherVdpViewsPreviousMTD: 'Mois préc. Autres vues PDV',
      otherVdpViewsMOM: 'Autres vues PDV MSM',
      otherVdpViewsPreviousYear: 'Autres vues PDV Année Préc.',
      otherVdpViewsYOY: 'Autres vues PDV ASA',
      vdpOtherViews: 'Autres vues PDV',
      vdpOtherViewsPreviousMonth: 'Mois préc. Autres vues PDV',
      vdpOtherViewsPreviousMTD: 'Mois préc. Autres vues PDV',
      vdpOtherViewsMOM: 'Autres vues PDV MSM',
      vdpOtherViewsPreviousYear: 'Autres vues PDV Année Préc.',
      vdpOtherViewsYOY: 'Autres vues PDV ASA',
      servicePageViews: 'Vues du service',
      servicePageViewsPreviousMonth: 'Mois préc. Vues du service',
      servicePageViewsPreviousMTD: 'Mois préc. Vues du service',
      servicePageViewsMOM: 'Vues du service MSM',
      servicePageViewsPreviousYear: 'Vues du service Année Préc.',
      servicePageViewsYOY: 'Vues du service ASA',
      totalVdps: 'Vues PDV totales',
      totalVdpsPreviousMonth: 'Mois préc. Vues PDV totales',
      totalVdpsPreviousMTD: 'Mois préc. Vues PDV totales',
      totalVdpsMOM: 'Vues PDV totales MSM',
      totalVdpsPreviousYear: 'Vues PDV totales Année Préc.',
      totalVdpsYOY: 'Vues PDV totales ASA',
      totalVdpViews: 'Nombre total de vues VDP',
      totalVdpViewsPreviousMonth: 'Nombre total de vues VDP Mois Préc.',
      totalVdpViewsPreviousMTD: 'Nombre total de vues VDP Mois Préc.',
      totalVdpViewsMOM: 'Nombre total de vues VDP MSM',
      totalVdpViewsPreviousYear: 'Nombre total de vues VDP Année Préc.',
      totalVdpViewsYOY: 'Nombre total de vues VDP ASA',
      vdpTotalViews: 'Nombre total de vues VDP',
      vdpTotalViewsPreviousMonth: 'Nombre total de vues VDP Mois Préc.',
      vdpTotalViewsPreviousMTD: 'Nombre total de vues VDP Mois Préc.',
      vdpTotalViewsMOM: 'Nombre total de vues VDP MSM',
      vdpTotalViewsPreviousYear: 'Nombre total de vues VDP Année Préc.',
      vdpTotalViewsYOY: 'Nombre total de vues VDP ASA',
      engagementRate: 'Taux d\'engagement',
      engagementRatePreviousMonth: 'Taux d’engagement Mois Préc.',
      engagementRatePreviousMTD: 'Taux d’engagement Mois Préc.',
      engagementRateMOM: 'Taux d\'engagement MSM',
      engagementRatePreviousYear: 'Taux d\'engagement Année Préc.',
      engagementRateYOY: 'Taux d\'engagement ASA',
      averageTimeOnSite: 'Temps sur le site',
      averageTimeOnSitePreviousMonth: 'Temps sur le site web Mois Préc.',
      averageTimeOnSitePreviousMTD: 'Temps sur le site web Mois Préc.',
      averageTimeOnSiteMOM: 'Temps sur le site MSM',
      averageTimeOnSitePreviousYear: 'Temps sur le site Année Préc.',
      averageTimeOnSiteYOY: 'Temps sur le site ASA',
      avgTimeOnSite: 'Temps sur le site',
      avgTimeOnSitePreviousMonth: 'Mois préc. Temps sur le site',
      avgTimeOnSiteMOM: 'Temps sur le site MSM',
      avgTimeOnSitePreviousYear: 'Temps sur le site Année Préc.',
      avgTimeOnSiteYOY: 'Temps sur le site ASA',
      bounces: 'Rebond',
      bouncesPreviousMonth: 'Rebond Mois Préc.',
      bouncesPreviousMTD: 'Rebond Mois Préc.',
      bouncesMOM: 'Rebond MSM',
      bouncesPreviousYear: 'Rebond Année Préc.',
      bouncesYOY: 'Rebond ASA',
      bounceRate: 'Taux de rebond',
      bounceRatePreviousMonth: 'Taux de rebond Mois Préc.',
      bounceRatePreviousMTD: 'Taux de rebond Mois Préc.',
      bounceRateMOM: 'Taux de rebond MSM',
      bounceRatePreviousYear: 'Taux de rebond Année Préc.',
      bounceRateYOY: 'Taux de rebond ASA',
      totalVisits: 'Visites du site Web',
      totalVisitsPreviousMonth: 'Mois préc. Visites du site Web',
      totalVisitsPreviousMTD: 'Visites du site Mois Préc.',
      totalVisitsMOM: 'Visites du site Web MSM',
      totalVisitsPreviousYear: 'Visites du site Web Année Préc.',
      totalVisitsYOY: 'Visites du site Web ASA',
      uniqueVisits: 'Visiteurs uniques',
      uniqueVisitsPreviousMonth: 'Visiteurs uniques Mois Préc.',
      uniqueVisitsPreviousMTD: 'Visiteurs uniques Mois Préc.',
      uniqueVisitsMOM: 'Visiteurs uniques MSM',
      uniqueVisitsPreviousYear: 'Visiteurs uniques Année Préc.',
      uniqueVisitsYOY: 'Visiteurs uniques ASA',
      visits: 'Visites',
      visitsPreviousMonth: 'Mois préc. Visites',
      visitsMOM: 'Visites MSM',
      visitsPreviousYear: 'Visites Année Préc.',
      visitsYOY: 'Visites ASA',
      engagements: 'Engagements',
      engagementsPreviousMonth: 'Engagements Mois Préc.',
      engagementsPreviousMTD: 'Engagements Mois Préc.',
      engagementsMOM: 'Engagements MSM',
      engagementsPreviousYear: 'Engagements Année Préc.',
      engagementsYOY: 'Engagements ASA',
      actions: 'Actions',
      actionsPreviousMonth: 'Actions Mois Préc.',
      actionsPreviousMTD: 'Actions Mois Préc.',
      actionsMOM: 'Actions MSM',
      actionsPreviousYear: 'Actions Année Préc.',
      actionsYOY: 'Actions ASA',
      actionRate: 'Taux d\'actions',
      actionRatePreviousMonth: 'Taux d’actions Mois Préc.',
      actionRatePreviousMTD: 'Taux d’actions Mois Préc.',
      actionRateMOM: 'Taux d\'actions MSM',
      actionRatePreviousYear: 'Taux d\'actions Année Préc.',
      actionRateYOY: 'Taux d\'actions ASA',
      formSubmissions: 'Les Soumissions De Formulaire',
      formSubmissionsPreviousMonth: 'Soumissions de Formulaire Mois Préc.',
      formSubmissionsPreviousMTD: 'Soumissions de Formulaire Mois Préc.',
      formSubmissionsMOM: 'Les Soumissions De Formulaire MSM',
      formSubmissionsPreviousYear: 'Les Soumissions De Formulaire Année Préc.',
      formSubmissionsYOY: 'Les Soumissions De Formulaire ASA',
      formConversionRate: 'Taux de conversion des formulaires',
      formConversionRatePreviousMonth: 'Taux de conversion des formulaires Mois Préc.',
      formConversionRatePreviousMTD: 'Taux de conversion des formulaires Mois Préc.',
      formConversionRateMOM: 'Taux de conversion des formulaires MSM',
      formConversionRatePreviousYear: 'Taux de conversion des formulaires Année Préc.',
      formConversionRateYOY: 'Taux de conversion des formulaires ASA',
      phoneCalls: 'Appels téléphoniques',
      phoneCallsPreviousMonth: 'Appels téléphoniques Mois Préc.',
      phoneCallsPreviousMTD: 'Appels téléphoniques Mois Préc.',
      phoneCallsMOM: 'Appels téléphoniques MSM',
      phoneCallsPreviousYear: 'Appels téléphoniques Année Préc.',
      phoneCallsYOY: 'Appels téléphoniques ASA',
      phoneConversionRate: 'Taux de conversion téléphonique',
      phoneConversionRatePreviousMonth: 'Taux de conversion téléphoniques Mois Préc.',
      phoneConversionRatePreviousMTD: 'Taux de conversion téléphoniques Mois Préc.',
      phoneConversionRateMOM: 'Taux de conversion téléphonique MSM',
      phoneConversionRatePreviousYear: 'Taux de conversion téléphonique Année Préc.',
      phoneConversionRateYOY: 'Taux de conversion téléphonique ASA',
      totalContacts: 'Nombre total de contacts',
      totalContactsPreviousMonth: 'Nombre total de contacts Mois Préc.',
      totalContactsPreviousMTD: 'Nombre total de contacts Mois Préc.',
      totalContactsMOM: 'Nombre total de contacts MSM',
      totalContactsPreviousYear: 'Nombre total de contacts Année Préc.',
      totalContactsYOY: 'Nombre total de contacts ASA',
      totalContactRate: 'Taux de contact total',
      totalContactRatePreviousMonth: 'Taux de contact total Mois Préc.',
      totalContactRatePreviousMTD: 'Taux de contact total Mois Préc.',
      totalContactRateMOM: 'Taux de contact total MSM',
      totalContactRatePreviousYear: 'Taux de contact total Année Préc.',
      totalContactRateYOY: 'Taux de contact total ASA',
      clickToCalls: 'Cliquer pour appeler',
      clickToCallsPreviousMonth: 'Cliquer pour appeler Mois Préc.',
      clickToCallsPreviousMTD: 'Cliquer pour appeler Mois Préc.',
      clickToCallsMOM: 'Cliquer pour appeler MSM',
      clickToCallsPreviousYear: 'Cliquer pour appeler Année Préc.',
      clickToCallsYOY: 'Cliquer pour appeler ASA',
      newInventoryDetailViews: 'Vue VDP Nouveaux',
      newInventoryDetailViewsPreviousMonth: 'Vue VDP Nouveaux Mois Préc.',
      newInventoryDetailViewsPreviousMTD: 'Vue VDP Nouveaux Mois Préc.',
      newInventoryDetailViewsMOM: 'Vue VDP Nouveaux MSM',
      newInventoryDetailViewsPreviousYear: 'Vue VDP Nouveaux Année Préc.',
      newInventoryDetailViewsYOY: 'Vue VDP Nouveaux ASA',
      usedInventoryDetailViews: 'Vue VDP d’occasion',
      usedInventoryDetailViewsPreviousMonth: 'Vue VDP d’occasion Mois Préc.',
      usedInventoryDetailViewsPreviousMTD: 'Vue VDP d’occasion Mois Préc.',
      usedInventoryDetailViewsMOM: 'Vue VDP d’occasion MSM',
      usedInventoryDetailViewsPreviousYear: 'Vue VDP d’occasion Année Préc.',
      usedInventoryDetailViewsYOY: 'Vue VDP d’occasion ASA',
      cpoInventoryDetailViews: 'Vues CPO VDP',
      cpoInventoryDetailViewsPreviousMonth: 'Vues CPO VDP Mois Préc.',
      cpoInventoryDetailViewsMOM: 'Vues CPO VDP MSM',
      cpoInventoryDetailViewsPreviousYear: 'Vues CPO VDP Année Préc.',
      cpoInventoryDetailViewsYOY: 'Vues CPO VDP ASA',
      pageViewsPerVisit: 'Pages vues par visite',
      pageViewsPerVisitPreviousMonth: 'Pages vues par visite Mois Préc.',
      pageViewsPerVisitPreviousMTD: 'Pages vues par visite Mois Préc.',
      pageViewsPerVisitMOM: 'Page Views Per Visit MSM',
      pageViewsPerVisitPreviousYear: 'Pages vues par visite Année Préc.',
      pageViewsPerVisitYOY: 'Pages vues par visite ASA',
      dealerEnrollments: 'Inscriptions des concessionnaires',
      averageActions: 'Moy. Actions',
      averageEngagementRate: 'Moy. Taux d\'engagement',
      averageVisitors: 'Moy. Visiteurs',
      totals: 'TOTAUX',
      vehiclemake: 'Marque du véhicule'
    }
  };
  public labels = {
    'en': {
      allmodels: 'All Models',
      devicetype: 'Device Type',
      engagedvisits: 'Engaged Visits',
      totalvisits: 'Total Visits',
      visitortrend: 'Visitor Trend',
      visits: 'Visits',
      uniquevisitors: 'Unique Visitors',
      engagementrate: 'Engagement Rate',
      actionrate: 'Action Rate',
      referrertype: 'Referrer Type',
      channel: 'Channel',
      referrerquality: 'Referrer Quality',
      vdps: 'VDPs',
      bouncerate: 'Bounce Rate',
      sales: 'Sales',
      service: 'Service',
      other: 'Other',
      bounces: 'Bounces',
      salesserviceotherbounces: 'Sales | Service | Other | Bounces',
      trafficbydevice: 'Traffic By Device',
      provider: 'Provider',
      desktop: 'Desktop',
      tablet: 'Tablet',
      mobile: 'Mobile',
      actions: 'Actions',
      engagement: 'Engagement',
      volume: 'Volume',
      pageviews: 'Page Views',
      uniquevisits: 'Unique Visits',
      timeonsite: 'Time on Site',
      vdpviews: 'VDP Views',
      formsubmissions: 'Form Submissions',
      clicktocalls: 'Click to Calls',
      calltrackingvolume: 'Call Tracking Volume',
      dealeravg: 'Dealer Avg',
      allproviders: 'All Providers',
      allsuppliers: 'All Suppliers',
      sm360: 'SM360',
      cdk: 'Sincro',
      foxdealer: 'Fox Dealer',
      dealercom: 'Dealer.com',
      edealer: 'EDealer',
      dealerinspire: 'Dealer Inspire',
      referrertypes: 'Referrer Types',
      phonecalls: 'Phone Calls',
      phoneconversionrate: 'Phone Conversion Rate',
      totalcontacts: 'Total Contacts',
      totalcontactrate: 'Total Contact Rate',
      newinventorydetailviews: 'New Inventory Detail Views',
      usedinventorydetailviews: 'Used Inventory Detail Views',
      cpoinventorydetailviews: 'CPO Inventory Detail Views',
    },
    'fr-CA': {
      allmodels: 'Tous les modèles',
      devicetype: 'Type d\'appareil',
      engagedvisits: 'Visites avec engagements',
      totalvisits: 'Total de visites',
      visitortrend: 'Tendances des visiteurs',
      visits: 'Visites',
      uniquevisitors: 'Visiteurs uniques',
      engagementrate: 'Taux d\'engagement',
      actionrate: 'Taux d\'actions',
      referrertype: 'Type référent',
      channel: 'Chaîne',
      referrerquality: 'Qualité du référent',
      vdps: 'VDP',
      bouncerate: 'Taux de rebond',
      sales: 'Ventes',
      service: 'Service',
      other: 'Autre',
      bounces: 'Rebond',
      salesserviceotherbounces: 'Ventes | Service | Autre | Rebond',
      trafficbydevice: 'Trafic selon l\'appareil',
      provider: 'Fournisseur',
      supplier: 'Fournisseur',
      desktop: 'Bureau',
      tablet: 'Tablette',
      mobile: 'Mobile',
      actions: 'Actions',
      engagement: 'Engagement',
      volume: 'Le volume',
      pageviews: 'Pages vues',
      uniquevisits: 'Visiteurs uniques',
      timeonsite: 'Temps sur le site',
      avgtimeonsite: 'Temps sur le site',
      vdpviews: 'Vues VDP',
      formsubmissions: 'Les Soumissions De Formulaire',
      clicktocalls: 'Cliquer pour appeler',
      calltrackingvolume: 'Nombre d\'appels',
      dealeravg: 'Concessionnaire moy',
      allproviders: 'Tous Les Fourniesseurs',
      allsuppliers: 'Tous Les Fourniesseurs',
      sm360: 'SM360',
      cdk: 'Sincro',
      foxdealer: 'Fox Dealer',
      dealercom: 'Dealer.com',
      edealer: 'EDealer',
      dealerinspire: 'Dealer Inspire',
      totals: 'TOTAUX',
      vehiclemake: 'Marque du véhicule',
      referrertypes: 'Types de référents',
      phonecalls: 'Appels téléphoniques',
      phoneconversionrate: 'Taux de conversion téléphonique',
      totalcontacts: 'Nombre total de contacts',
      totalcontactrate: 'Taux de contact total',
      newinventorydetailviews: 'Vues VDP Nouveaux',
      usedinventorydetailviews: 'Vues VDP d’occasion',
      cpoinventorydetailviews: 'Vues CPO VDP',
    }
  };

  public chartDimensions = {
    'en': {
      allchannels: 'All Channels',
      typedbookmarked: 'Typed/Bookmarked',
      thirdpartysites: 'Third Party Sites',
      organicsearch: 'Organic Search',
      paidsocial: 'Paid Social',
      organicsocial: 'Organic Social',
      paidsearch: 'Paid Search',
      other: 'Other',
      paiddisplay: 'Paid Display',
      brand: 'Brand',
      paidemail: 'Paid Email',
      paidvideo: 'Paid Video',
      organicemail: 'Organic Email',
      allproviders: 'All Suppliers',
      allsuppliers: 'All Suppliers',
    },
    'fr-CA': {
      allchannels: 'Toutes Les Chaînes',
      allmodels: 'Tous les modèles',
      typedbookmarked: 'Dactylographié/ajouté à ses favori',
      thirdpartysites: 'Sites Tiers',
      organicsearch: 'Recherche organique',
      paidsocial: 'Social payant',
      organicsocial: 'Social organique',
      paidsearch: 'Recherche payante',
      other: 'Autres',
      paiddisplay: 'Affichage payant',
      brand: 'Marque',
      paidvideo: 'Vidéo payante',
      paidemail: 'Courriel payant',
      organicemail: 'Courriel organique',
      allproviders: 'Tous Les Fourniesseurs',
      allsuppliers: 'Tous Les Fourniesseurs',
    }
  };

  public channels = {
    'en': {
      allchannels: 'All Channels',
      allmodels: 'All Models',
      thirdpartysites: 'Third Party Sites',
      typedbookmarked: 'Typed/Bookmarked',
      organicsearch: 'Organic Search',
      paidsocial: 'Paid Social',
      organicsocial: 'Organic Social',
      paidsearch: 'Paid Search',
      other: 'Other',
      paiddisplay: 'Paid Display',
      brand: 'Brand',
      paidvideo: 'Paid Video',
      paidemail: 'Paid Email',
      organicemail: 'Organic Email',
      allproviders: 'All Suppliers',
      allsuppliers: 'All Suppliers',
    },
    'fr-CA': {
      allchannels: 'Toutes Les Chaînes',
      allmodels: 'Tous les modèles',
      thirdpartysites: 'Sites Tiers',
      typedbookmarked: 'Dactylographié/ajouté à ses favori',
      organicsearch: 'Recherche organique',
      paidsocial: 'Social payant',
      organicsocial: 'Social organique',
      paidsearch: 'Recherche payante',
      other: 'Autre',
      paiddisplay: 'Affichage payant',
      brand: 'Marque',
      paidemail: 'Courriel payant',
      paidvideo: 'Vidéo payante',
      organicemail: 'Courriel organique',
      allproviders: 'Tous Les Fourniesseurs',
      allsuppliers: 'Tous Les Fourniesseurs',
    }
  };
}
