import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap, withLatestFrom, take, switchMap } from 'rxjs/operators';
import { SharedTranslationConfig } from '../../../_config/translation/shared/shared.translation.config';
import { ResourceStringMap } from '../../models/models-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';

@Injectable()
export class SharedTranslationService {
  sharedTranslations = new SharedTranslationConfig;
  appSectionTranslations: any;
  navLabelTranslations: any;  
  resourceStringMap: ResourceStringMap;

  constructor(
    private store$: Store<AppState>
  ) {
    this.store$.select(AppSelectors.selectConfigState).pipe(
      filter(config => !!config),
      map(config => {
        return config.resourceStrings;
      })
    ).subscribe(rs => {
      this.resourceStringMap = rs;
    })
  }

  getLabelTranslation(value: string, locale?: string): string {
    const v4locale = locale == 'en-US' ? 'en' : locale;
    const v5locale = locale == 'en' ? 'en-US' : locale;
    const key = value?.toLowerCase().replace(/[^A-Z0-9]+/ig, '');

    const v4result = this.sharedTranslations?.labels?.[v4locale]?.[key];
    const v5result = this.resourceStringMap?.[locale]?.[key];

    //console.log(value, locale, '|', key, '|', v5result, v4result);

    return v5result || v4result || value || null;
  }
}

